import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import InnerZoneWrapper from "./InnerZoneWrapper";

class DropZone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      isDragActive: false,
      error: null,
      uploadingImage: null,
      uploading: false,
    };

    this.inputRef = createRef();
  }

  componentDidMount() {
    document.addEventListener("dragover", this.toggleDropBoxVisible);
    document.addEventListener("dragleave", this.handleDragLeave);
    document.addEventListener("drop", this.handleDragLeave);

    if (this.props.image) {
      this.setState({ uploadingImage: this.props.image });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("dragover", this.toggleDropBoxVisible);
    document.removeEventListener("dragleave", this.handleDragLeave);
    document.removeEventListener("drop", this.handleDragLeave);
  }

  componentDidUpdate(prevProps) {
    if (this.props.file && this.props.file !== prevProps.file) {
      this.handleUpload(this.props.file);
    }

    if (this.props.image !== prevProps.image) {
      this.setState({
        uploadingImage: this.props.image,
      });
    }
  }

  preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  openUpload = () => {
    this.inputRef.current.click();
  };

  toggleDropBoxVisible = (e) => {
    this.preventDefault(e);
    if (!this.state.isDragging) {
      this.setState({ isDragging: true });
    }
  };

  handleDragLeave = (e) => {
    this.preventDefault(e);
    this.setState({ isDragging: false, isDragActive: false });
  };

  handleDragOverInside = (e) => {
    this.preventDefault(e);
    this.setState({ isDragActive: true });
  };

  handleDrop = (e) => {
    if (e.dataTransfer.files === undefined || e.dataTransfer.files.length === 0)
      return;
    this.preventDefault(e);
    const droppedFile = e.dataTransfer.files[0];
    this.handleUpload(droppedFile);
  };

  handleChangeInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.handleUpload(file);
    }
  };

  handleUpload = async (file) => {
    if (this.props.usePreview) {
      try {
        this.setState({
          isDragging: false,
          isDragActive: false,
          uploading: true,
          error: null,
          uploadingImage: { file: file },
        });
        const fileUrl = await this.getFileUrl(file);

        this.setState((prevStates) => {
          return {
            ...prevStates,
            uploadingImage: { ...prevStates.uploadingImage, url: fileUrl },
          };
        });

        const params = {
          now:
            this.props.uploadParams?.now?.join(",") ||
            this.props.imageSize?.join(","),
          later: this.props.uploadParams?.later?.join(","),
          public: this.props.uploadParams?.public,
        };

        const imageResp = await this.props.uploadImage(
          this.props.uploadLocation,
          file,
          { params }
        );

        if (imageResp && imageResp.data) {
          const uploadingImage = {
            ...this.state.uploadingImage,
            url: imageResp.data.url,
          };

          this.setState({
            uploading: false,
            uploadingImage,
          });
          this.props.onResult(uploadingImage);
        }
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.description
        ) {
          this.setState({
            error: err.response.data.description,
            uploading: false,
          });
        } else {
          this.setState({
            error: err.message,
            uploading: false,
          });
        }
        console.log(err);
      }
    } else {
      try {
        this.setState({
          isDragging: false,
          isDragActive: false,
          error: null,
          uploadingImage: { file: file },
        });
        const fileUrl = await this.getFileUrl(file);

        if (fileUrl) {
          this.props.onResult({
            file,
            url: fileUrl,
          });
        }
      } catch (err) {
        this.setState({
          error: err.message,
        });
      }
    }
  };

  getFileUrl = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        if (!this.props.isImage(file.type)) {
          reject(new Error("Invalid File Type"));
        }
        if (file.size > this.props.sizeLimit) {
          reject(new Error("File Too Large"));
        }

        resolve(e.target.result);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new Error("Problem parsing input file."));
      };

      reader.readAsDataURL(file);
    });
  };

  removeUploadingImage = (event) => {
    event.stopPropagation();
    this.setState({ uploading: false, uploadingImage: null }, () => {
      this.props.onResult(this.state.uploadingImage);
    });
  };

  resetDropzone = (event) => {
    event.stopPropagation();
    this.setState({ error: null, uploadingImage: null });
  };

  render() {
    const {
      error,
      isDragging,
      isDragActive,
      uploadingImage,
      uploading,
    } = this.state;
    const {
      circleBoundary,
      onResult,
      innerZone,
      subtitle,
      previewTitle,
      usePreview,
      ...props
    } = this.props;
    return (
      <DropBox
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOverInside}
        onDrop={this.handleDrop}
        onClick={this.openUpload}
        {...props}
      >
        <input
          type="file"
          id="file"
          onChange={this.handleChangeInput}
          ref={this.inputRef}
        />
        <BoxInput
          dragging={isDragging}
          dragActive={isDragActive}
          circleBoundary={circleBoundary}
        >
          <InnerZoneWrapper
            innerZone={innerZone}
            subtitle={subtitle}
            uploadingImage={uploadingImage}
            previewTitle={previewTitle}
            uploading={uploading}
            removeImage={this.removeUploadingImage}
            handleUpload={this.handleUpload}
            error={error}
            resetDropzone={this.resetDropzone}
            usePreview={usePreview}
          />
        </BoxInput>

        {/* TODO: should move to DropZoneWithChildren
        {!isDragging && !error && !uploadingImage &&children && (
          <ChildrenWrapper>{children}</ChildrenWrapper>
        )} */}
      </DropBox>
    );
  }
}

DropZone.propTypes = {
  /**
   * Function to upload an image.
   * @param {string} location - The location where the image should be uploaded.
   * @param {File} file - The file object representing the image to be uploaded.
   * @param {object} config - Additional configuration parameters for the upload.
   * @returns {Promise} - A promise that resolves with the upload response.
   */
  uploadImage: PropTypes.shape({
    location: PropTypes.string.isRequired,
    file: PropTypes.instanceOf(File).isRequired,
    config: PropTypes.object.isRequired,
  }).isRequired,

  /**
   * Function to check if a given MIME type is an image.
   * @param {string} mimeType - The MIME type to check.
   * @returns {boolean} - Returns true if the MIME type is an image, false otherwise.
   */
  isImage: PropTypes.func.isRequired,
  onResult: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  subtitle: PropTypes.string,
  sizeLimit: PropTypes.number,
  circleBoundary: PropTypes.bool,
  innerZone: PropTypes.node,
  // @deprecated, use uploadParams.now instead
  imageSize: PropTypes.array,
  file: PropTypes.object,
  image: PropTypes.object,
  previewTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  usePreview: PropTypes.bool,
  uploadParams: PropTypes.shape({
    now: PropTypes.arrayOf(PropTypes.string),
    later: PropTypes.arrayOf(PropTypes.string),
    public: PropTypes.bool,
  }),
  uploadLocation: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

DropZone.defaultProps = {
  imageSize: ["thumb", "sm", "md", "lg"],
  usePreview: true,
};

const DropBox = styled.form`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  min-height: ${({ height }) => (height ? `${height}px` : "200px")};
  position: relative;
  display: flex;
  cursor: pointer;
  background-color: var(--color-surface-over);

  input[type="file"] {
    display: none;
  }
`;

const BoxInput = styled.div`
  position: relative;
  border: 2px dashed var(--color-stroke)};
  border-radius: ${({ circleBoundary }) =>
    circleBoundary ? "50%" : "var(--radius-lg)"};
  padding: 20px;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  flex: 1;

  ${({ dragging }) =>
    dragging &&
    css`
      opacity: 1;
    `};

  ${({ dragActive }) =>
    dragActive &&
    css`
      border: 2px solid var(--color-active-primary)};
      background-color: var(--color-active-lighten80);
    `};
`;

export default DropZone;
