/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from "react";
import { Button } from "./Button";
import { IconButtonProps } from "./Button.types";

export const IconButton = forwardRef(
  (props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { icon, "aria-label": ariaLabel, defaultTooltip, ...rest } = props;
    const defaultTooltipEnabled = defaultTooltip !== false;
    return (
      <Button
        iconButton
        ref={ref}
        data-tooltip-content={defaultTooltipEnabled ? ariaLabel : undefined}
        data-tooltip-id={
          defaultTooltipEnabled ? "simple-shared-tooltip" : undefined
        }
        aria-label={ariaLabel}
        {...rest}
      >
        {icon}
      </Button>
    );
  }
);
