import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/drop-zone/package.json";
import { DropZone } from "../../../../../packages/drop-zone/src/index";
import { TextDelta } from "@happeouikit/typography";
import { active } from "@happeouikit/colors";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  DropZone,
  TextDelta,
  active,
  React
};