import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { DropZone } from '../src/index';
import { TextDelta } from "@happeouikit/typography";
import { active } from "@happeouikit/colors";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dropzone"
    }}>{`Dropzone`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <p>{`Drag and drop of file uploading`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={1} __code={'<div style={{ width: 480, height: 200, margin: \'auto\' }}>\n  <DropZone\n    previewTitle=\"PreviewTitle\"\n    image={{\n      url:\n        \'https://storage.googleapis.com/spacegapps_uploads/08897bfa-2153-4340-9bd7-fe387a3219a7\',\n    }}\n    subtitle=\"This is subtitle\"\n    onResult={file => console.log(file)}\n    uploadLocation={{ name: \'organisation\', id: 1 }}\n    uploadParams={{ public: true }}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      DropZone,
      TextDelta,
      active,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: 480,
        height: 200,
        margin: 'auto'
      }}>
        <DropZone previewTitle='PreviewTitle' image={{
          url: 'https://storage.googleapis.com/spacegapps_uploads/08897bfa-2153-4340-9bd7-fe387a3219a7'
        }} subtitle='This is subtitle' onResult={file => console.log(file)} uploadLocation={{
          name: "organisation",
          id: 1
        }} uploadParams={{
          public: true
        }} mdxType="DropZone" />
    </div>
    </Playground>
    <h2 {...{
      "id": "dropzone-with-innerzone"
    }}>{`Dropzone with `}<inlineCode parentName="h2">{`innerZone`}</inlineCode></h2>
    <p>{`Modify the content of dropzone with `}<inlineCode parentName="p">{`innerZone`}</inlineCode>{` props.`}</p>
    <Playground __position={2} __code={'<div style={{ width: 200, margin: \'auto\' }}>\n  <DropZone\n    onResult={url => console.log(url)}\n    innerZone={\n      <div\n        style={{\n          width: \'100%\',\n          height: 150,\n          display: \'flex\',\n          justifyContent: \'center\',\n          alignItems: \'center\',\n        }}\n      >\n        <TextDelta color={active} style={{ textAlign: \'center\' }}>\n          Custom dropzone\n        </TextDelta>\n      </div>\n    }\n    uploadLocation={{ name: \'organisation\', id: 1 }}\n    uploadParams={{ public: true }}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      DropZone,
      TextDelta,
      active,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: 200,
        margin: 'auto'
      }}>
        <DropZone onResult={url => console.log(url)} innerZone={<div style={{
          width: '100%',
          height: 150,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
                    <TextDelta color={active} style={{
            textAlign: 'center'
          }} mdxType="TextDelta">Custom dropzone</TextDelta>
                </div>} uploadLocation={{
          name: "organisation",
          id: 1
        }} uploadParams={{
          public: true
        }} mdxType="DropZone" />
    </div>
    </Playground>
    <h2 {...{
      "id": "file-props"
    }}><inlineCode parentName="h2">{`file`}</inlineCode>{` props`}</h2>
    <p>{`Pass a file to the DropZone through `}<inlineCode parentName="p">{`file`}</inlineCode>{` props to tell DropZone to handle the file. If `}<inlineCode parentName="p">{`onlyImage`}</inlineCode>{` is `}<inlineCode parentName="p">{`true`}</inlineCode>{`, a preview will be shown.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={DropZone} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      